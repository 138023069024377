$grey: #E6E6E6
$brand: #003B4D
$sans-font: 'Montserrat', sans-serif
// Sizes
$small: 500px
$tablet: 600px
$desktop: 900px

@mixin border-hover
	background-image: linear-gradient(90deg, rgba($brand, 1), rgba($brand, 1))
	background-repeat: no-repeat
	background-size: 0% 1px
	background-position: bottom left
	display: inline
	transition: background-size 350ms ease-out

	&:hover
		background-size: 100% 1px
		background-image: linear-gradient(90deg, rgba($brand, 1), rgba($brand, 1))
	

*, *::before, *::after
	box-sizing: border-box
	margin: 0
	padding: 0

body
	font-family: $sans-font
	font-weight: 500
	font-style: normal
	font-size: 16px
	line-height: 1.1em
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	text-rendering: optimizeLegibility

a
	text-decoration: none
	color: $brand
	display: inline-block
	line-height: 1.5em

img
	width: 100%

main
	background-color: white
	border: 20px solid $brand
	min-height: 100vh
	height: 100%
	display: flex
	flex-direction: column
	justify-content: space-between
	padding: 2rem

	@media screen and (min-width: $tablet)
		align-items: flex-end
		flex-direction: row
		padding: 2rem 2.5rem 2.5rem 3rem

figure
	img
		max-width: 180px
		@media screen and (min-width: $desktop)
			max-width: 440px
		&.mobile-logo
			@media screen and (min-width: $desktop)
				display: none
	span
		display: none
		@media screen and (min-width: $desktop)
			display: block
	
.column
	&.left
		padding-bottom: 2rem
		@media screen and (min-width: $tablet)
			padding-bottom: 0
			width: 50%
	
	&.right
		@media screen and (min-width: $tablet)
			width: 32%
		@media screen and (min-width: $desktop)
			width: 28%

address
	display: flex
	flex-direction: column
	font-style: normal
	font-size: .9rem
	align-items: flex-start
	a
		+border-hover()

span.location
	padding-bottom: 1rem
	margin-bottom: 1rem
	background-image: linear-gradient(90deg, rgba($brand, 1), rgba($brand, 0))
	background-repeat: no-repeat
	background-size: 100% 1px
	background-position: bottom left
	width: 100%


br.mobile-break
	position: relative
	@media screen and (min-width: $small)
		display: none

span.desktop-comma
	display: none
	@media screen and (min-width: $small)
		display: inline
	
a.push
	margin-bottom: 2px